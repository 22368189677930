// TODO: REFACTORING
import React, { useEffect } from 'react';
import Style from './style.module.css';

const Animation404 = ({ text, cards }) => {
  const components = [
    'cloudTopLeft',
    'cloudTopLeft2',
    'cloudTopRight2',
    'cloudTopRight',
    'mountain',
    'mountain2',
    'mountain3',
    'cloudBottomLeft',
    'cloudBottomRight',
    'cloudBottomLeft2',
    'cloudBottomRight2',
    'numberLeft',
    'numberCenter',
    'numberRight',
    'airplane',
    'airplane2',
    'text',
    'cardsWrapper',
  ];

  function addStagetoAnimation(components) {
    components.map(componentId => {
      document.getElementById(componentId).classList.add(Style.two);
    });
    setTimeout(() => {
      components.map(componentId => {
        document.getElementById(componentId).classList.add(Style.three);
      });
    }, 5000);
  }

  useEffect(() => {
    setTimeout(addStagetoAnimation(components), 1000);
  });

  return (
    <section className={Style.animationSection} id="screen">
      <div className={Style.cloudTopLeft} id="cloudTopLeft"></div>
      <div className={Style.cloudTopLeft2} id="cloudTopLeft2"></div>
      <div className={Style.cloudTopRight2} id="cloudTopRight2"></div>
      <div className={Style.cloudTopRight} id="cloudTopRight"></div>

      <div className={Style.mountain} id="mountain"></div>
      <div className={Style.mountain2} id="mountain2"></div>
      <div className={Style.mountain3} id="mountain3"></div>

      <div className={Style.cloudBottomLeft} id="cloudBottomLeft"></div>
      <div className={Style.cloudBottomRight} id="cloudBottomRight"></div>
      <div className={Style.cloudBottomLeft2} id="cloudBottomLeft2"></div>
      <div className={Style.cloudBottomRight2} id="cloudBottomRight2"></div>

      <div className={Style.numberLeft} id="numberLeft"></div>
      <div className={Style.numberCenter} id="numberCenter"></div>
      <div className={Style.numberRight} id="numberRight"></div>

      <div className={Style.airplane} id="airplane"></div>
      <div className={Style.airplane2} id="airplane2"></div>
      <div className={Style.text} id="text">
        {text}
      </div>

      <div className={Style.cardsWrapper} id="cardsWrapper">
        {cards.map((card, index) => {
          return (
            <div className={Style.card} key={index}>
              <a href={card.link}>
                <img
                  src={card.image}
                  alt={card.title}
                  className={Style.cardImage}
                />
              </a>
              <a href={card.link} className={Style.cardText}>
                {card.title}
              </a>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Animation404;
