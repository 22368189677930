import React from 'react';
import Layout from '../components/layout';
import Animation404 from '../components/Animation404';
import Image from '../images/home_sp.jpeg';

const sectionsProps = {
  text: 'Ops! Não encontramos essa página',
  cards: [
    {
      image: Image,
      link: '',
      title: 'Frota',
    },
    {
      image: Image,
      link: '',
      title: 'Home',
    },
    {
      image: Image,
      link: '',
      title: 'Blog',
    },
  ],
};

const NotFoundPage = ({ location }) => {
  const path = location.pathname;

  const translations = [
    { path: path, polylang_current_lang: 'en_US' },
    { path: path, polylang_current_lang: 'es_ES' },
    { path: path, polylang_current_lang: 'pt_BR' },
    // { path: path, polylang_current_lang: 'it_IT' },
  ];

  return (
    <Layout
      solidHeader="transparent"
      showNewsletter={false}
      showFooter={false}
      translations={translations}
      useTranslationsPath={false}
    >
      <Animation404 {...sectionsProps} />
    </Layout>
  );
};

export default NotFoundPage;
